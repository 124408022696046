<template>
  <div v-if="isShow">
    <div class="content">
      <el-form
        label-width="120px"
        :model="chainData"
        ref="chainRef"
        :rules="chainRule"
      >
        <div class="mainTop">
          <el-form-item
            label="场景名称："
            style="width: 470px"
            prop="sceneName"
          >
            <el-input v-model="chainData.sceneName"></el-input>
          </el-form-item>
          <el-form-item
            label="场景属性："
            style="width: 470px"
            prop="sceneName"
          >
            <el-radio-group v-model="chainData.sceneProperties">
              <!-- <el-radio label="通用场景">通用场景</el-radio> -->
              <el-radio label="特殊场景">特殊场景</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="场景描述：" prop="supplyChainDescribe">
            <el-input
              v-model="chainData.sceneDescribe"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              style="width: 80%;"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联场景：" prop="supplyChainDescribe">
            <el-select
              v-model="chainData.associatedScenarios"
              filterable
              allow-create
              remote
              reserve-keyword
              placeholder="请输入关键词"
              @change="associatedScenariosChange"
              :remote-method="remoteMethod"
              :loading="loading"
              style="width: 470px"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="行业标签：">
            <dic-checkbox-group
              :code.sync="chainData.industryCategoriesList"
              tag="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="场景图片：">
            <upload-image
              :imageUrl.sync="chainData.sceneImgUrl"
              :saveUrl.sync="chainData.sceneImg"
            />
          </el-form-item>
        </div>
      </el-form>
      <div
        v-for="(item, index) in scenarioBehaviorDtoList"
        :key="index"
        style="padding: 0 20px 10px; background-color: #fff"
        class="bottomStyle"
      >
        <el-form label-width="150px" :model="item">
          <div class="flexStyle">
            <div>
              <el-form-item label="一级场景：">
                <el-input v-model="item.behaviorName"></el-input>
                <el-dropdown>
                  <span class="el-dropdown-link" style="cursor: pointer">
                    <i class="el-icon-circle-plus-outline"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="add('one')"
                      >添加一级</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="add('two')"
                      >添加一、二级</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="add('three')"
                      >添加一、二、三级</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <i
                  @click="delet(index)"
                  class="el-icon-remove-outline"
                  v-if="index > 0"
                ></i>
              </el-form-item>
              <el-form-item label="一级场景关联标签：" label-width="150px">
                <el-cascader
                  :options="scenarioLabelOptionsFirst"
                  filterable
                  :props="{
                    value: 'scenarioLabel',
                    label: 'scenarioLabel',
                    children: 'childNode',
                    checkStrictly: true,
                  }"
                  @change="scenarioLabelOptionsFirstChange"
                  v-model="item.scenarioLabelList"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="一级场景描述：">
                <el-input
                  v-model="item.behaviorDescribe"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 8 }"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="一级场景图片：" style="margin-left: 20px">
                <upload-image
                  :imageUrl.sync="item.behaviorImgUrl"
                  :saveUrl.sync="item.behaviorImg"
                />
              </el-form-item>
            </div>
          </div>
          <div
            v-for="(el, indexSmall) in item.childNode"
            :key="indexSmall"
            style="padding-left: 100px"
          >
            <div class="flexStyle">
              <div>
                <el-form-item label="二级场景：">
                  <el-input v-model="el.behaviorName"></el-input>
                  <el-dropdown>
                    <span class="el-dropdown-link" style="cursor: pointer">
                      <i class="el-icon-circle-plus-outline"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="addmiddle(index, 'one')"
                        >添加二级</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="addmiddle(index, 'two')"
                        >添加二、三级</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <i
                    @click="deletSecond(index, indexSmall)"
                    class="el-icon-remove-outline"
                  ></i>
                </el-form-item>
                <!-- <el-form-item label="二级场景关联标签：" label-width="150px">
                  <el-cascader
                    :options="scenarioLabelOptionsFirst"
                    filterable
                    :props="{
                      value: 'scenarioLabel',
                      label: 'scenarioLabel',
                      children: 'childNode',
                      checkStrictly: true
                    }"
                    :key="cascaderKey"
                    @change="scenarioLabelOptionsSecondChange"
                    v-model="el.scenarioLabelList"
                  ></el-cascader>
                </el-form-item> -->
                <el-form-item label="二级场景描述：">
                  <el-input
                    v-model="el.behaviorDescribe"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 8 }"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="二级场景图片：" style="margin-left: 20px">
                  <upload-image
                    :imageUrl.sync="el.behaviorImgUrl"
                    :saveUrl.sync="el.behaviorImg"
                  />
                </el-form-item>
              </div>
            </div>
            <div
              v-for="(item1, index1) in el.childNode"
              :key="index1"
              style="padding-left: 100px"
            >
              <div class="flexStyle">
                <div>
                  <el-form-item label="三级场景：">
                    <el-input v-model="item1.behaviorName"></el-input>
                    <el-dropdown>
                      <span class="el-dropdown-link" style="cursor: pointer">
                        <i class="el-icon-circle-plus-outline"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="addsmall(index, indexSmall, 'one')"
                          >添加三级</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <i
                      @click="deleteThird(index, indexSmall, index1)"
                      class="el-icon-remove-outline"
                    ></i>
                  </el-form-item>
                  <!-- <el-form-item label="三级场景关联标签：" label-width="150px">
                    <el-cascader
                      :options="scenarioLabelOptionsFirst"
                      filterable
                      :props="{
                        value: 'scenarioLabel',
                        label: 'scenarioLabel',
                        children: 'childNode',
                        checkStrictly: true
                      }"
                      v-model="item1.scenarioLabelList"
                    ></el-cascader>
                  </el-form-item> -->
                  <el-form-item label="三级行为描述：">
                    <el-input
                      v-model="item1.behaviorDescribe"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 8 }"
                    ></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item
                    label="三级场景图片："
                    style="margin-left: 20px"
                  >
                    <upload-image
                      :imageUrl.sync="item1.behaviorImgUrl"
                      :saveUrl.sync="item1.behaviorImg"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">创建人：{{ chainData.createName }}</div>
        <div class="ess_people_list">修改人：{{ chainData.updateName }}</div>
        <div class="ess_people_list">创建时间：{{ chainData.createTimes }}</div>
        <div class="ess_people_list">修改时间：{{ chainData.updateTimes }}</div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import UploadImage from "@/components/UploadImage.vue";
import {
  insertScenarioBasic,
  selectById,
  updateScenarioBasic,
  getByAssociatedScenarios,
  getByScenarioLabel,
} from "@/api/Sceneknowledge.js";
export default {
  name: "App",
  components: {
    DicCheckboxGroup,
    UploadImage,
  },
  watch: {
    "chainData.id": {
      handler(newVal) {
        if (this.chainData && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    setTimeout(() => {
      if (this.personType) {
        this.search();
      } else if (!this.personType) {
        this.isShow = true;
      }
    }, 500);
    setTimeout(() => {
      this.sadasdsadas = true;
    }, 2000);
  },
  computed: {
    personData() {
      return Number(this.$route.query.id);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  data() {
    return {
      cascaderKey: 1,
      loading: false,
      isShow: false,
      sadasdsadas: false,
      options: [],
      scenarioLabelOptionsFirst: [],
      scenarioLabelOptionsSecond: [],
      scenarioLabelOptionsThird: [],
      chainData: {
        sceneName: "", //场景名称
        sceneProperties: "特殊场景", //场景属性
        sceneDescribe: "", //场景描述
        industryCategoriesList: [], //行业类别
        supplyChainIndustryChild: [],
        sceneImg: "", //场景图片
        associatedScenarios: "", //关联场景
      },
      scenarioBehaviorDtoList: [
        {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
          scenarioLabelList: ["1111"],
          childNode: [
            {
              behaviorName: "", //行为
              behaviorDescribe: "", //描述
              behaviorImg: "", //图片短地址
              behaviorImgUrl: "", //图片长地址
              scenarioLabelList: [],
              childNode: [
                {
                  behaviorName: "", //行为
                  behaviorDescribe: "", //描述
                  behaviorImg: "", //图片短地址
                  behaviorImgUrl: "", //图片长地址
                  scenarioLabelList: [],
                },
              ],
            },
          ],
        },
      ],
      chainRule: {
        sceneName: [
          { required: true, message: "请填写场景名称", trigger: "blur" },
        ],
        sceneProperties: [
          { required: true, message: "请选择场景属性", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const sceneName = query;
          const res = await getByAssociatedScenarios({ sceneName: sceneName });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    async associatedScenariosChange() {
      const res1 = await getByScenarioLabel({
        sceneName: this.chainData.associatedScenarios,
      });
      if (res1.code == 200) {
        this.scenarioLabelOptionsFirst = res1.data;
      }
    },
    async search() {
      const res = await selectById({ id: this.personData });
      if (res.code == 200) {
        this.chainData = res.data.scenarioBasicDto;
        this.scenarioBehaviorDtoList = res.data.scenarioBehaviorDtoList;
        if (!this.chainData.industryCategories) {
          this.chainData.industryCategoriesList = [];
        } else {
          this.chainData.industryCategoriesList =
            this.chainData.industryCategories.split(",");
        }
        if (this.chainData.associatedScenarios) {
          this.associatedScenariosChange();
        }
        if (this.chainData.updateTime) {
          this.chainData.updateTimes = this.timestampToTime(
            this.chainData.updateTime
          );
        }
        if (this.chainData.createTime) {
          this.chainData.createTimes = this.timestampToTime(
            this.chainData.createTime
          );
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    saveItem() {
      Object.assign(this.chainData, {
        Authorization: this.Authorization,
      });
      let res;
      this.$refs.chainRef.validate(async (valid) => {
        //async
        if (valid) {
          if (
            this.chainData.supplyChainIndustryChild.length > 0 &&
            this.chainData.supplyChainIndustryChild
          ) {
            this.chainData.industryCategoriesList =
              this.chainData.industryCategoriesList.concat(
                this.chainData.supplyChainIndustryChild
              );
          }
          const params = {
            scenarioBasicDto: this.chainData,
            scenarioBehaviorDtoList: this.scenarioBehaviorDtoList,
          };
          if (!this.personType) {
            res = await insertScenarioBasic(params);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            }
          } else {
            res = await updateScenarioBasic(params);
            if (res.code === 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            }
          }
        }
      });
    },
    cancelItem() {
      this.$router.go(-1);
    },
    handleClose(tag) {
      const index = this.chainData.fatherIds.indexOf(tag);
      this.chainData.fatherIds.splice(index, 1);
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

      var Y = date.getFullYear() + "-";

      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";

      var D = date.getDate();

      var h = date.getHours();

      var m = date.getMinutes();

      var s = date.getSeconds();

      return (
        Y +
        M +
        this.add0(D) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(m) +
        ":" +
        this.add0(s)
      ); //时分秒可以根据自己的需求加上
    },
    selectOne() {
      const valueOne = this.options.filter(
        (item) => item.id === this.chainData.father
      );
      this.chainData.fatherIds.push(valueOne[0]);
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "行业":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "技术":
          this.chainData.supplyChainIndustryChild = val;
          break;

        default:
          break;
      }
    },
    add(data) {
      let value = {};
      if (data == "three") {
        value = {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
          childNode: [
            {
              behaviorName: "", //行为
              behaviorDescribe: "", //描述
              behaviorImg: "", //图片短地址
              behaviorImgUrl: "", //图片长地址
              childNode: [
                {
                  behaviorName: "", //行为
                  behaviorDescribe: "", //描述
                  behaviorImg: "", //图片短地址
                  behaviorImgUrl: "", //图片长地址
                },
              ],
            },
          ],
        };
      } else if (data == "two") {
        value = {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
          childNode: [
            {
              behaviorName: "", //行为
              behaviorDescribe: "", //描述
              behaviorImg: "", //图片短地址
              behaviorImgUrl: "", //图片长地址
            },
          ],
        };
      } else if (data == "one") {
        value = {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
        };
      }

      this.scenarioBehaviorDtoList.push(value);
    },
    addmiddle(indexBig, data) {
      let value = {};
      if (data == "two") {
        value = {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
          childNode: [
            {
              behaviorName: "", //行为
              behaviorDescribe: "", //描述
              behaviorImg: "", //图片短地址
              behaviorImgUrl: "", //图片长地址
            },
          ],
        };
      } else if (data == "one") {
        value = {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
        };
      }
      this.scenarioBehaviorDtoList[indexBig].childNode.push(value);
    },
    addsmall(indexBig, indexMiddle, data) {
      let value = {};
      if (data == "one") {
        value = {
          behaviorName: "", //行为
          behaviorDescribe: "", //描述
          behaviorImg: "", //图片短地址
          behaviorImgUrl: "", //图片长地址
        };
      }
      this.scenarioBehaviorDtoList[indexBig].childNode[
        indexMiddle
      ].childNode.push(value);
    },
    delet(index) {
      this.scenarioBehaviorDtoList.splice(index, 1);
    },
    deletSecond(index, indexSmall) {
      this.scenarioBehaviorDtoList[index].childNode.splice(indexSmall, 1);
    },
    deleteThird(index, indexSmall, index1) {
      this.scenarioBehaviorDtoList[index].childNode[
        indexSmall
      ].childNode.splice(index1, 1);
    },
    // 职能查询关联标签
    scenarioLabelOptionsFirstChange(val) {
      const data = this.scenarioLabelOptionsFirst;
      data.forEach((item) => {
        if (item.scenarioLabel == val[0] && item.childNode) {
          this.scenarioLabelOptionsSecond = item.childNode;
        }
      });
    },
    scenarioLabelOptionsSecondChange(val) {
      const data = this.scenarioLabelOptionsSecond;
      data.forEach((item) => {
        if (item.scenarioLabel == val[0] && item.childNode) {
          this.scenarioLabelOptionsThird = item.childNode;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px 20px 0 20px;
  // margin-bottom: 20px;
}

.content {
  overflow: auto;
  width: 100%;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
// /deep/.el-textarea {
//   width: 80% !important;
//   // min-height: 40px !important;
// }
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.bottomStyle {
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
  /deep/ .el-upload--picture-card {
    width: 150px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 150px;
    height: 100px;
    // line-height: 150px;
  }
  /deep/ .el-upload {
    background-color: #fff;
  }
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.flexStyle {
  display: flex;
  margin: 10px 10px 10px 0;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
